import { GetStaticProps, InferGetStaticPropsType } from 'next'
import {
  Posts,
  PostsVariables,
  Posts_posts,
} from '@/apollo/__generated__/Posts'

import { POSTS } from '@/apollo/queries'
import { PostStatusType } from '@/apollo/__generated__/globalTypes'
import { Project } from '@/typings/project'
import { __draft_post_enabled__ } from '@/constants'
import client from '@/apollo/apollo-client'

export { default } from '@/page-components/home'

export const getStaticProps: GetStaticProps<{
  posts: Posts_posts[] | null
  projects: Project[]
}> = async () => {
  const { data } = await client.query<Posts, PostsVariables>({
    query: POSTS,
    variables: {
      take: 3,
      where: !__draft_post_enabled__
        ? { status: { equals: PostStatusType.Published } }
        : undefined,
    },
  })

  const projects: Project[] = [
    {
      id: 1,
      name: 'Cosmelt',
      description:
        'is a project management company operational across the globe',
      thumbnail:
        'https://res.cloudinary.com/hfq3eckim/image/upload/v1644393317/cms-demo/Projects%20Thumbnails/cosmelt_1_dlpcln.png',
      url: 'https://www.cosmelt.com/',
      galleries: [
        {
          id: 1,
          src: 'https://res.cloudinary.com/hfq3eckim/image/upload/v1644393317/cms-demo/Projects%20Thumbnails/cosmelt_1_dlpcln.png',
        },
        {
          id: 2,
          src: 'https://res.cloudinary.com/hfq3eckim/image/upload/v1644393313/cms-demo/Projects%20Thumbnails/cosmelt_2_nhszex.png',
        },
        {
          id: 3,
          src: 'https://res.cloudinary.com/hfq3eckim/image/upload/v1644393316/cms-demo/Projects%20Thumbnails/cosmelt_3_lnwgrf.png',
        },
        {
          id: 4,
          src: 'https://res.cloudinary.com/hfq3eckim/image/upload/v1644393313/cms-demo/Projects%20Thumbnails/cosmelt_4_x9d6nt.png',
        },
        {
          id: 5,
          src: 'https://res.cloudinary.com/hfq3eckim/image/upload/v1644393315/cms-demo/Projects%20Thumbnails/cosmelt_5_bok6xp.png',
        },
      ],
      tags: [
        'Frontend Development',
        'Web Design',
        '3D Design',
        'Cloud Computing',
        'Hosting Services',
        'Quality Assurance',
        'Custom Animations',
        'Payment Processing',
      ],
      bgColor: '#484848',
      color: '#40E0D0',
      descriptionColor: '#fff',
    },
    {
      id: 2,
      name: '3arabian',
      description: 'is your quickest path to learning the Arabic Language',
      thumbnail:
        'https://res.cloudinary.com/hfq3eckim/image/upload/v1644393309/cms-demo/Projects%20Thumbnails/3arabian_1_rguggi.png',
      url: 'https://3arabian.com/',
      galleries: [
        {
          id: 1,
          src: 'https://res.cloudinary.com/hfq3eckim/image/upload/v1644393309/cms-demo/Projects%20Thumbnails/3arabian_1_rguggi.png',
        },
        {
          id: 2,
          src: 'https://res.cloudinary.com/hfq3eckim/image/upload/v1644393309/cms-demo/Projects%20Thumbnails/3arabian_2_v3msmv.png',
        },
        {
          id: 3,
          src: 'https://res.cloudinary.com/hfq3eckim/image/upload/v1644393309/cms-demo/Projects%20Thumbnails/3arabian_3_nd0yxl.png',
        },
        {
          id: 4,
          src: 'https://res.cloudinary.com/hfq3eckim/image/upload/v1644393311/cms-demo/Projects%20Thumbnails/3arabian_4_jjzghk.png',
        },
        {
          id: 5,
          src: 'https://res.cloudinary.com/hfq3eckim/image/upload/v1644393309/cms-demo/Projects%20Thumbnails/3arabian_5_olonf7.png',
        },
      ],
      tags: [
        'Frontend Development',
        'Web Design',
        'Hosting Services',
        'Quality Assurance',
        'Custom Animations',
        'Booking System',
      ],
      bgColor: '#FCF3E2',
      color: '#1EB9AC',
      descriptionColor: '#000',
    },
    {
      id: 3,
      name: 'MEMCO',
      description:
        'is the exclusive agent of Max Weishaupt Middle East and GCC',
      thumbnail:
        'https://res.cloudinary.com/hfq3eckim/image/upload/v1644395561/cms-demo/Projects%20Thumbnails/memco_1_zkoazf.png',
      url: 'https://memco-group.me/',
      galleries: [
        {
          id: 1,
          src: 'https://res.cloudinary.com/hfq3eckim/image/upload/v1644395561/cms-demo/Projects%20Thumbnails/memco_1_zkoazf.png',
        },
        {
          id: 2,
          src: 'https://res.cloudinary.com/hfq3eckim/image/upload/v1644395561/cms-demo/Projects%20Thumbnails/memco_2_ogx6b7.png',
        },
        {
          id: 3,
          src: 'https://res.cloudinary.com/hfq3eckim/image/upload/v1644393315/cms-demo/Projects%20Thumbnails/memco_3_p51msw.png',
        },
        {
          id: 4,
          src: 'https://res.cloudinary.com/hfq3eckim/image/upload/v1644393314/cms-demo/Projects%20Thumbnails/memco_4_j0buby.png',
        },
        {
          id: 5,
          src: 'https://res.cloudinary.com/hfq3eckim/image/upload/v1644393317/cms-demo/Projects%20Thumbnails/memco_5_dbs9jy.png',
        },
      ],
      tags: [
        'Frontend Development',
        'Web Design',
        '3D Design',
        'Cloud Computing',
        'Hosting Services',
        'Quality Assurance',
        'Custom Animations',
        'Payment Processing',
      ],
      bgColor: '#000',
      color: '#C51F25',
      descriptionColor: '#fff',
    },

    {
      id: 4,
      name: 'Amero',
      description:
        'is a digital crypto-ecosystem based on synthesized diamonds',
      thumbnail:
        'https://res.cloudinary.com/hfq3eckim/image/upload/v1644393310/cms-demo/Projects%20Thumbnails/amero_1_bh8fkv.png',
      url: 'https://amero.ae/',
      galleries: [
        {
          id: 1,
          src: 'https://res.cloudinary.com/hfq3eckim/image/upload/v1644393312/cms-demo/Projects%20Thumbnails/amero_2_tm63ey.png',
        },
        {
          id: 2,
          src: 'https://res.cloudinary.com/hfq3eckim/image/upload/v1644393313/cms-demo/Projects%20Thumbnails/amero_3_hgcoey.png',
        },
        {
          id: 3,
          src: 'https://res.cloudinary.com/hfq3eckim/image/upload/v1644393311/cms-demo/Projects%20Thumbnails/amero_4_zsiamd.png',
        },
        {
          id: 4,
          src: 'https://res.cloudinary.com/hfq3eckim/image/upload/v1644393312/cms-demo/Projects%20Thumbnails/amero_5_wisdqi.png',
        },
        {
          id: 5,
          src: 'https://res.cloudinary.com/hfq3eckim/image/upload/v1644393313/cms-demo/Projects%20Thumbnails/amero_6_gbqyn5.png',
        },
      ],
      tags: [
        'Frontend Development',
        'Web Design',
        'Hosting Services',
        'Quality Assurance',
        'Custom Animations',
      ],
      bgColor: '#C3DFF5',
      color: '#3B5772',
      descriptionColor: '#fff',
    },
    {
      id: 5,
      name: 'Numoo',
      description: 'is the leading online coaching platform in the MENA region',
      thumbnail:
        'https://res.cloudinary.com/hfq3eckim/image/upload/v1644393315/cms-demo/Projects%20Thumbnails/numoo_1_cnnzh7.png',
      url: 'https://www.numoo.net',
      galleries: [
        {
          id: 1,
          src: 'https://res.cloudinary.com/hfq3eckim/image/upload/v1644393318/cms-demo/Projects%20Thumbnails/numoo_2_hdk2e3.png',
        },
        {
          id: 2,
          src: 'https://res.cloudinary.com/hfq3eckim/image/upload/v1644393317/cms-demo/Projects%20Thumbnails/numoo_3_jbfitv.png',
        },
        {
          id: 3,
          src: 'https://res.cloudinary.com/hfq3eckim/image/upload/v1644393317/cms-demo/Projects%20Thumbnails/numoo_4_eil6ta.png',
        },
        {
          id: 4,
          src: 'https://res.cloudinary.com/hfq3eckim/image/upload/v1644393319/cms-demo/Projects%20Thumbnails/numoo_5_bq26ub.png',
        },
        {
          id: 5,
          src: 'https://res.cloudinary.com/hfq3eckim/image/upload/v1644393317/cms-demo/Projects%20Thumbnails/numoo_6_oy7ffx.png',
        },
      ],
      tags: [
        'Frontend Development',
        'Web Design',
        'Cloud Computing',
        'Backend Development',
        'Hosting Services',
        'Quality Assurance',
        'Custom Animations',
        'Payment Processing',
        'Booking System',
      ],
      bgColor: '#553B90',
      color: '#FAAF4A',
      descriptionColor: '#fff',
    },
  ]
  return {
    props: {
      posts: data.posts,
      projects,
    },
    revalidate: 10,
  }
}

export type HomePageProps = InferGetStaticPropsType<typeof getStaticProps>
