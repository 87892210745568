import { Button, Container, Heading } from '@chakra-ui/react'

import HeroBannerLogo from './HeroBannerLogo'
import Link from 'next/link'
import { Section } from '@/layouts'
import Typewriter from 'typewriter-effect'

const HeroBanner = () => {
  return (
    <Section display="flex" position="relative" minHeight="100vh" my={0}>
      <HeroBannerLogo />
      <Container maxWidth="container.xl" m="auto">
        <Heading
          as="h1"
          size="3xl"
          fontSize={{ base: '2xl', xs: '3xl', md: '4xl', lg: '6xl' }}
          mt={{ base: '6', lg: '12' }}
          lineHeight="short"
        >
          American Engineering
        </Heading>
        <Heading
          as="h1"
          fontSize={{ base: '2xl', xs: '3xl', md: '4xl', lg: '6xl' }}
          mb={{ base: '4', lg: '8' }}
          lineHeight="short"
        >
          Affordable Rates
        </Heading>
        <Heading
          as="h2"
          fontSize={{ base: 'lg', xs: 'xl', md: '2xl', lg: '3xl' }}
          fontWeight="normal"
        >
          We deliver digital success
        </Heading>
        <Heading
          as="h2"
          fontSize={{ base: 'lg', xs: 'xl', md: '2xl', lg: '3xl' }}
          fontWeight="normal"
          sx={{
            div: {
              display: 'inline-block',
            },
          }}
        >
          with&nbsp;
          <Typewriter
            options={{
              strings: ['Integrity.', 'Quality.', 'Trust.'],
              autoStart: true,
              loop: true,
            }}
          />
        </Heading>
        <Link href="/contact-us" passHref>
          <Button id="quote-cta-btn" as="a" size="lg" fontSize="xl" mt="6">
            Get a Free Quote
          </Button>
        </Link>
      </Container>
    </Section>
  )
}

export default HeroBanner
