import 'swiper/css'
import 'swiper/css/autoplay'

import { Box, Button, Center, Flex, VStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Autoplay } from 'swiper'
import Link from 'next/link'
import LottiePlayer from 'react-lottie-player'
import { Section } from '@/layouts'
import { Testimonial } from '@/components'
import lottieImage from '@/assets/lotties/chacra-testimonial.json'

const Testimonials = () => {
  const [play, setPlay] = useState(true)

  return (
    <Section maxW="container.lg">
      <VStack w="full" spacing={{ base: '0', lg: '8' }}>
        <Flex
          w="full"
          direction={{
            base: 'column-reverse',
            md: 'column-reverse',
            lg: 'row',
          }}
          bgColor={{ base: 'none', md: 'none', lg: '#F3FFF8' }}
          rounded={{ base: 'none', md: 'none', lg: '3xl' }}
        >
          <Flex
            bgColor={{ base: 'white', md: 'white', lg: '#F3FFF8' }}
            rounded={{ base: '3xl', md: '3xl', lg: '3xl' }}
            justifyContent="flex-end"
            flexDir="column"
            flex="40%"
          >
            <LottiePlayer
              animationData={lottieImage}
              play={play}
              loop={false}
              goTo={0}
            />
          </Flex>
          <Box flex="60%" overflow="hidden">
            <Box w="full" bgColor="#F3FFF8" rounded="3xl" alignSelf="center">
              <Swiper
                modules={[Autoplay]}
                slidesPerView={1}
                autoplay={{
                  disableOnInteraction: false,
                  delay: 3000,
                }}
                loop={true}
                onSlideChange={() => {
                  setPlay(false)
                }}
                onSlideChangeTransitionEnd={() => {
                  setPlay(true)
                }}
              >
                <SwiperSlide>
                  <Testimonial
                    title="Delivered Above Expectations..."
                    message="They delivered  above expectations in terms of quality and communication."
                    name="Mahmoud Elsoukie"
                    occupation="Founder, Construction Company"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <Testimonial
                    title="Delivered Value for Money..."
                    message="They delivered value for money. Their overall business acumen and professionalism are impressive."
                    name="Ahmad Sharaf Aldine"
                    occupation="Co-Founder, NUMOO"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <Testimonial
                    title="Ability To Quickly Implement..."
                    message="Their understanding of the technology they work on and ability to quickly implement complex solutions were impressive."
                    name="Aileen Miralles"
                    occupation="Project Manager, Cosmelt"
                  />
                </SwiperSlide>
              </Swiper>
            </Box>
          </Box>
        </Flex>
        <Center>
          <Link
            href="https://www.clutch.co/profile/chacra-software-solutions-0#reviews"
            passHref
          >
            <Button
              id="clutch-learn-btn"
              target="_blank"
              as="a"
              size="lg"
              w={{ base: '300px', md: '600px', lg: 'auto' }}
            >
              Read Clutch Reviews
            </Button>
          </Link>
        </Center>
      </VStack>
    </Section>
  )
}

export default Testimonials
