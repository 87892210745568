import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react'

import Link from 'next/link'
import ReactPlayer from 'react-player'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { Section } from '@/layouts'
import { __prod__ } from '@/constants'
import gsap from 'gsap'
import useIsomorphicLayoutEffect from '@/hooks/useIsomorphicLayoutEffect'
import useSelector from '@/hooks/useSelector'

const OurServices = () => {
  const [q, ref] = useSelector<HTMLDivElement>(null)

  useIsomorphicLayoutEffect(() => {
    ScrollTrigger.matchMedia({
      '(min-width: 48em)': function () {
        gsap.to(q('#our-services-container'), {
          xPercent: -50,
          scrollTrigger: {
            id: 'our-services',
            trigger: ref.current,
            pin: true,
            start: 'top top',
            end: () => `+=3000`,
            scrub: 1,
          },
        })
      },
    })

    return () => {
      ScrollTrigger.clearMatchMedia()
    }
  }, [])

  return (
    <>
      <Section
        ref={ref}
        display={{ base: 'none', md: 'block' }}
        maxW="full"
        p={0}
        overflow="hidden"
      >
        <Flex id="our-services-container" h="100vh" w="200%">
          <Container flex={1} maxW="1680px" h="full" px={12}>
            <Flex w="full" h="full">
              <Flex
                flex="30%"
                justify="center"
                direction="column"
                textAlign="left"
              >
                <Heading size="2xl" mb="6" color="darkgreen">
                  Custom Built <br /> Software
                </Heading>
                <Text fontSize="2xl" mb="2">
                  Websites and web apps <br /> that make you stand out.
                </Text>
                <UnorderedList
                  fontSize="2xl"
                  sx={{
                    'li::before': {
                      content: '"- "',
                      marginLeft: '-24px',
                    },
                  }}
                  listStyleType="none"
                  mb="6"
                >
                  <ListItem>Enhanced SEO</ListItem>
                  <ListItem>Modern animations</ListItem>
                  <ListItem>Performance analytics</ListItem>
                  <ListItem>Lightweight & responsive</ListItem>
                </UnorderedList>
                <Link href="/contact-us" passHref>
                  <Button id="software-start-cta-btn-web" as="a" size="lg">
                    Start Now
                  </Button>
                </Link>
              </Flex>
              <Box flex="70%">
                <ReactPlayer
                  url="https://res.cloudinary.com/hfq3eckim/video/upload/v1643018581/csv2/Software_Dev_Render_Green_ver_nuloyx.webm"
                  playing
                  loop
                  muted
                  height="100vh"
                  width="100%"
                />
              </Box>
            </Flex>
          </Container>
          <Container flex={1} maxW="1680px" h="full" px={12}>
            <Flex w="full" h="full">
              <Flex
                flex="30%"
                justify="center"
                direction="column"
                textAlign="left"
              >
                <Heading size="2xl" mb="6" color="darkgreen">
                  Design & <br />
                  Marketing
                </Heading>
                <Text fontSize="2xl" mb="2">
                  Branding and digital marketing <br /> that amplify your
                  message.
                </Text>
                <UnorderedList
                  fontSize="2xl"
                  sx={{
                    'li::before': {
                      content: '"- "',
                      marginLeft: '-24px',
                    },
                  }}
                  listStyleType="none"
                  mb="6"
                >
                  <ListItem>Business branding</ListItem>
                  <ListItem>UI/UX design</ListItem>
                  <ListItem>Video &amp; animations</ListItem>
                  <ListItem>Digital marketing</ListItem>
                </UnorderedList>
                <Link href="/contact-us" passHref>
                  <Button id="marketing-start-cta-btn-web" as="a" size="lg">
                    Start Now
                  </Button>
                </Link>
              </Flex>
              <Box flex="70%">
                <ReactPlayer
                  url="https://res.cloudinary.com/hfq3eckim/video/upload/v1643018582/csv2/Marketing_Render_green_ver_dmmyth.webm"
                  playing
                  loop
                  muted
                  height="100vh"
                  width="100%"
                />
              </Box>
            </Flex>
          </Container>
        </Flex>
      </Section>
      <Section display={{ md: 'none' }}>
        <VStack spacing={{ base: 20, md: 40 }} fontSize="xl">
          <Box>
            <ReactPlayer
              url={[
                {
                  src: 'https://res.cloudinary.com/hfq3eckim/video/upload/v1643018581/csv2/Software_Dev_Render_Green_ver_nuloyx.webm',
                  type: 'video/webm',
                },
                {
                  src: 'https://res.cloudinary.com/hfq3eckim/video/upload/v1644224488/csv2/Software_Dev_Render_u8vu4r.mp4',
                  type: 'video/mp4',
                },
              ]}
              playing
              playsinline
              loop
              muted
              height="100%"
              width="100%"
            />
            <Heading size="xl" mb="6" color="darkgreen" mt={12}>
              Custom Built <br /> Software
            </Heading>
            <Text mb="6">
              Websites and web apps <br /> that make you stand out.
            </Text>
            <UnorderedList
              sx={{
                'li::before': {
                  content: '"- "',
                  marginLeft: '-24px',
                },
              }}
              listStyleType="none"
              mb="6"
            >
              <ListItem>Enhanced SEO</ListItem>
              <ListItem>Modern animations</ListItem>
              <ListItem>Performance analytics</ListItem>
              <ListItem>Lightweight &amp; responsive</ListItem>
            </UnorderedList>
            <Link href="/contact-us" passHref>
              <Button
                id="software-start-cta-btn-mob"
                as="a"
                size="lg"
                isFullWidth
                maxW="300px"
                mt={6}
              >
                Start Now
              </Button>
            </Link>
          </Box>
          <Box>
            <ReactPlayer
              url={[
                {
                  src: 'https://res.cloudinary.com/hfq3eckim/video/upload/v1643018582/csv2/Marketing_Render_green_ver_dmmyth.webm',
                  type: 'video/webm',
                },
                {
                  src: 'https://res.cloudinary.com/hfq3eckim/video/upload/v1644224495/csv2/Marketing_Render_gkztji.mp4',
                  type: 'video/mp4',
                },
              ]}
              playing
              playsinline
              loop
              muted
              height="100%"
              width="100%"
            />
            <Heading size="xl" mb="6" color="darkgreen" mt={12}>
              Design &amp; <br />
              Marketing
            </Heading>
            <Text mb="6">
              Branding and digital marketing <br /> that amplify your message.
            </Text>
            <UnorderedList
              sx={{
                'li::before': {
                  content: '"- "',
                  marginLeft: '-24px',
                },
              }}
              listStyleType="none"
              mb="6"
            >
              <ListItem>Business branding</ListItem>
              <ListItem>UI/UX design</ListItem>
              <ListItem>Video &amp; animations</ListItem>
              <ListItem>Digital marketing</ListItem>
            </UnorderedList>
            <Link href="/contact-us" passHref>
              <Button
                id="marketing-start-cta-btn-mob"
                as="a"
                size="lg"
                isFullWidth
                maxW="300px"
                mt={6}
              >
                Start Now
              </Button>
            </Link>
          </Box>
        </VStack>
      </Section>
    </>
  )
}

export default OurServices
