import { Box, Flex } from '@chakra-ui/react'

import Image from 'next/image'
import Logo1 from '@/assets/images/Logo-01.svg'
import { MotionBox } from '@/components'
import { Variants } from 'framer-motion'

export interface HeroBannerLogoProps {}

const HeroBannerLogo = (_props: HeroBannerLogoProps) => {
  const variants: Variants = {
    slideUp: {
      y: '-10%',
      opacity: 0,
    },
    slideDown: {
      y: '40%',
      opacity: 1,
      transition: {
        duration: 0.7,
        delay: 0.5,
        damping: 14,
        stiffness: 100,
        type: 'spring',
      },
    },
  }

  return (
    <Flex
      position="absolute"
      top="-40"
      left="0"
      w="full"
      h="130vh"
      py={{ base: '4', md: '10' }}
    >
      <Box h="full" w="full" opacity="0.27" maxW="720px" m="auto">
        <MotionBox
          h={{ base: 'full', xs: 'full', md: 'full', lg: 'full', xl: 'full' }}
          w="full"
          position="relative"
          variants={variants}
          initial="slideUp"
          animate="slideDown"
        >
          <Image
            alt="chacra-logo"
            src={Logo1}
            layout="fill"
            objectFit="contain"
            draggable={false}
            priority
          />
        </MotionBox>
      </Box>
    </Flex>
  )
}

export default HeroBannerLogo
