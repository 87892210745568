import {
  Box,
  Flex,
  Heading,
  Wrap,
  WrapItem,
  useColorMode,
} from '@chakra-ui/react'

import OurValuesInfoItem from './OurValuesInfoItem'
import { Section } from '@/layouts'
import { __prod__ } from '@/constants'
import gsap from 'gsap'
import useIsomorphicLayoutEffect from '@/hooks/useIsomorphicLayoutEffect'
import { useRef } from 'react'
import useSelector from '@/hooks/useSelector'

const OurValues = () => {
  const { setColorMode } = useColorMode()
  const [q, ref] = useSelector<HTMLDivElement>(null)
  const bgPatternTl = useRef<gsap.core.Timeline>()
  const bgColorAndHeadingTl = useRef<gsap.core.Timeline>()
  const itemsTl = useRef<gsap.core.Timeline>()

  // bg pattern animation
  useIsomorphicLayoutEffect(() => {
    bgPatternTl.current = gsap.timeline({ repeat: -1 }).to(ref.current, {
      backgroundPosition: '250px -250px',
      duration: 8,
      ease: 'none',
    })

    return () => {
      bgPatternTl.current?.kill()
    }
  }, [])

  useIsomorphicLayoutEffect(() => {
    const clipPath = gsap.to(ref.current, {
      clipPath: 'inset(20% 20% 20%)',
      scrollTrigger: {
        markers: false,
        trigger: q('#block-container'),
        scrub: 1,
        start: () => '+=2000 top-=20%',
        end: () => '+=80%',
      },
    })

    return () => {
      clipPath.kill()
      clipPath.scrollTrigger?.kill()
    }
  }, [])

  useIsomorphicLayoutEffect(() => {
    itemsTl.current = gsap
      .timeline({
        scrollTrigger: {
          id: 'our-values-section',
          pin: true,
          trigger: ref.current,
          scrub: true,
          start: () => 'top',
          end: () => '+=2000',
          onLeave: () => {
            setColorMode('light')
          },
          markers: false,
        },
      })
      .fromTo(
        q('.info-item'),
        {
          opacity: 0,
          yPercent: 100,
        },
        {
          opacity: 1,
          yPercent: 0,
          stagger: 0.3,
        }
      )

    return () => {
      itemsTl.current?.kill()
      itemsTl.current?.scrollTrigger?.kill()
    }
  }, [])

  useIsomorphicLayoutEffect(() => {
    bgColorAndHeadingTl.current = gsap
      .timeline({
        scrollTrigger: {
          id: 'bg-color-and-heading',
          trigger: q('#our-values-heading'),
          toggleActions: 'play none none reverse',
          start: () => 'center center',
          end: () => 'center center',
          onEnter: () => {
            setColorMode('dark')
          },
          onEnterBack: () => {
            setColorMode('light')
          },
          markers: false,
        },
      })
      .fromTo(
        q('#our-values-heading'),
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.2,
          ease: 'none',
        }
      )
      .to(ref.current, {
        backgroundColor: '#000000',
        duration: 0.2,
        ease: 'none',
      })

    return () => {
      bgColorAndHeadingTl.current?.kill()
      bgColorAndHeadingTl.current?.scrollTrigger?.kill()
    }
  }, [])

  return (
    <Section
      ref={ref}
      h="100vh"
      minW="full"
      p={0}
      m={0}
      bgImage="/bg-pattern-1.png"
      bgRepeat="repeat"
    >
      <Box id="block-container" h="100vh" w="full">
        <Flex
          h="full"
          fontFamily="Bebas Neue"
          fontWeight="normal"
          align="center"
          direction="column"
          minH="inherit"
          w="full"
          color="white"
          overflow="hidden"
        >
          <Heading id="our-values-heading" size="3xl" mt={14} mb={6}>
            Our Values
          </Heading>
          <Flex className="our-values" flex={1} justify="center" align="center">
            <Wrap spacing={10} align="center">
              <WrapItem
                className="info-item"
                w={{ base: 'full', md: 'inherit' }}
              >
                <OurValuesInfoItem
                  heading="Client Driven"
                  description="We bring tremendous energy into every project we start."
                />
              </WrapItem>
              <WrapItem
                className="info-item"
                w={{ base: 'full', md: 'inherit' }}
              >
                <OurValuesInfoItem
                  heading="Quality Focused"
                  description="We set clear expectations and deliver them as promised."
                />
              </WrapItem>
              <WrapItem
                className="info-item"
                w={{ base: 'full', md: 'inherit' }}
              >
                <OurValuesInfoItem
                  heading="Transparency Oriented"
                  description="We are highly responsive and maintain open communication."
                />
              </WrapItem>
            </Wrap>
          </Flex>
        </Flex>
      </Box>
    </Section>
  )
}

export default OurValues
