import { Box, Text, useColorMode } from '@chakra-ui/react'
import { FadeUpMask, MotionBox } from '@/components'
import { MainLayout, Section, Section2 } from '@/layouts'
import { useEffect, useState } from 'react'

import ChacraHeart from '@/assets/lotties/chacra-heart.json'
import Head from 'next/head'
import { HomePageProps } from '@/pages'
import LottiePlayer from 'react-lottie-player'
import dynamic from 'next/dynamic'

const DynamicFeaturedArticles = dynamic(
  () => import('@/page-components/home/FeaturedArticles/FeaturedArticles')
)
const DynamicFeaturedProjects = dynamic(
  () => import('@/page-components/home/FeaturedProjects/FeaturedProjects')
)
const DynamicHeroBanner = dynamic(
  () => import('@/page-components/home/HeroBanner/HeroBanner')
)
const DynamicOurServices = dynamic(
  () => import('@/page-components/home/OurServices/OurServices')
)
const DynamicOurValues = dynamic(
  () => import('@/page-components/home/OurValues/OurValues')
)
const DynamicTestimonials = dynamic(
  () => import('@/page-components/home/Testimonials/Testimonials')
)

export const Home = (props: HomePageProps) => {
  const [playChacraHeart, setPlayChacraHeart] = useState(false)

  const { setColorMode } = useColorMode()
  useEffect(() => {
    setColorMode('light')
  }, [setColorMode])

  return (
    <MainLayout noPaddingTop noHeaderLogo>
      <Head>
        <title>Chacra Software</title>
      </Head>
      <DynamicHeroBanner />
      <Section2
        h="70vh"
        fontSize={{ base: '2xl', md: '3xl' }}
        textAlign="center"
        color="darkgreen"
        mt={40}
      >
        <Box display={{ base: 'none', md: 'block' }}>
          <FadeUpMask>
            <Text>
              <Text as="strong">Founded in 2018</Text>, Chacra Software
            </Text>
          </FadeUpMask>
          <FadeUpMask>
            <Text>is the premier software services provider</Text>
          </FadeUpMask>
          <FadeUpMask>
            <Text>in the MENA Region</Text>
          </FadeUpMask>
        </Box>
        <Box display={{ md: 'none' }}>
          <FadeUpMask>
            <Text>
              <Text as="strong">Founded in 2018</Text>, Chacra Software is the
              premier software services provider in the MENA Region
            </Text>
          </FadeUpMask>
        </Box>
      </Section2>
      <DynamicOurServices />
      <Section2
        fontSize={{ base: '2xl', md: '3xl', lg: '4xl' }}
        textAlign="center"
        color="darkgreen"
        fontWeight="medium"
        h={{ base: '80vh', md: '100vh' }}
      >
        <Box display={{ base: 'none', md: 'block' }}>
          <FadeUpMask>
            <Text>It&apos;s personal.</Text>
          </FadeUpMask>
          <FadeUpMask>
            <Text>When our partners engage us,</Text>
          </FadeUpMask>
          <FadeUpMask>
            <Text>we consider their project our own.</Text>
          </FadeUpMask>
        </Box>
        <Box display={{ md: 'none' }}>
          <FadeUpMask>
            <Text>
              It&apos;s personal.
              <br />
              When our partners engage us, we consider their project our own.
            </Text>
          </FadeUpMask>
        </Box>
      </Section2>
      <DynamicFeaturedProjects projects={props.projects} />
      <Section2
        fontSize={{ base: '2xl', md: '3xl', lg: '4xl' }}
        textAlign="center"
        color="darkgreen"
        fontWeight="medium"
        h={{ base: '80vh', md: '100vh' }}
      >
        <Box display={{ base: 'none', md: 'block' }}>
          <FadeUpMask>
            <Text>Our passion, drive, and purpose</Text>
          </FadeUpMask>
          <FadeUpMask>
            <Text>are to elevate our partners</Text>
          </FadeUpMask>
          <FadeUpMask>
            <Text>ideas to their highest potential.</Text>
          </FadeUpMask>
        </Box>
        <Box display={{ md: 'none' }}>
          <FadeUpMask>
            <Text>
              Our passion, drive, and purpose are to elevate our partners ideas
              to their highest potential.
            </Text>
          </FadeUpMask>
        </Box>
      </Section2>
      <DynamicOurValues />
      <Section2
        fontSize={{ base: 'lg', md: '2xl', lg: '3xl' }}
        textAlign="center"
        color="darkgreen"
        h={{ base: '40vh', md: 'max-content' }}
        my={{ base: '10', md: '48', lg: '48' }}
      >
        <Box display={{ base: 'none', md: 'block' }}>
          <FadeUpMask>
            <Text>
              <strong>Our partners</strong> experience tremendous success
            </Text>
          </FadeUpMask>
          <FadeUpMask>
            <Text>when working with us. See what</Text>
          </FadeUpMask>
          <FadeUpMask>
            <Text>they say about us</Text>
          </FadeUpMask>
        </Box>
        <Box display={{ md: 'none' }}>
          <FadeUpMask>
            <Text>
              <strong>Our partners</strong> experience tremendous success when
              working with us. See what they say about us
            </Text>
          </FadeUpMask>
        </Box>
      </Section2>
      <DynamicTestimonials />
      <Section
        fontSize={{ base: 'lg', md: '2xl', lg: '3xl' }}
        textAlign="center"
        color="darkgreen"
        display="flex"
        flexDirection="column"
        justifyContent="space-around"
        alignItems="center"
        h={{ base: '60vh', md: 'max-content' }}
        my={{ base: '20', md: '48', lg: '48' }}
      >
        <Box display={{ base: 'none', md: 'block' }}>
          <FadeUpMask>
            <Text>
              <strong>Giving back</strong> is at the core of our identity.
            </Text>
          </FadeUpMask>
          <FadeUpMask>
            <Text>We offer special rates to charities and NGOs</Text>
          </FadeUpMask>
          <FadeUpMask>
            <Text>that work hard to improve our community.</Text>
          </FadeUpMask>
        </Box>

        <Box display={{ md: 'none' }}>
          <FadeUpMask>
            <Text>
              <strong>Giving back</strong> is at the core of our identity. We
              offer special rates to charities and NGOs that work hard to
              improve our community.
            </Text>
          </FadeUpMask>
        </Box>
        <MotionBox
          pt={{ base: '0px', lg: '130px' }}
          viewport={{ once: true, amount: 'all' }}
          onViewportEnter={() => setPlayChacraHeart(true)}
        >
          <LottiePlayer
            animationData={ChacraHeart}
            play={playChacraHeart}
            loop={false}
            segments={[0, 66]}
          />
        </MotionBox>
      </Section>
      {props.posts && <DynamicFeaturedArticles posts={props.posts} />}
    </MainLayout>
  )
}
