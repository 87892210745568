import 'swiper/css/pagination'
import 'swiper/css'

import { Autoplay, Pagination } from 'swiper'
import { Box, Button, Center, Flex, Grid } from '@chakra-ui/react'
import { PostCard, PostModal } from '@/components'
import { Swiper, SwiperSlide } from 'swiper/react'

import Link from 'next/link'
import { Posts_posts } from '@/apollo/__generated__/Posts'
import { Section } from '@/layouts'

export interface FeaturedArticlesProps {
  posts: Posts_posts[]
}

const FeaturedArticles = (props: FeaturedArticlesProps) => {
  const featuredPost = props.posts.map((post) => (
    <SwiperSlide key={post.id}>
      <Box mx={4} mt={4} mb={50}>
        <PostModal post={post}>
          <PostCard
            postTitle={post.postTitle ?? undefined}
            slug={post.slug ?? undefined}
            tag={post.type ?? undefined}
            publishDate={post.publishDate}
            views={post.views}
            outlineColor={post.outlineColor ?? undefined}
            highlightColor={post.highlightColor ?? undefined}
          />
        </PostModal>
      </Box>
    </SwiperSlide>
  ))

  return (
    <Section
      id="featured-articles"
      maxW="container.xl"
      minH={{ md: '40vh', lg: '40vh' }}
      pb={{ base: 20, lg: 4 }}
      px={{ base: 0, lg: 4 }}
    >
      <Flex
        justify="center"
        align="center"
        direction="column"
        minH="inherit"
        w="full"
      >
        <Grid
          display={{ base: 'none', md: 'inline-grid' }}
          templateColumns={{
            base: 'repeat(1,1fr)',
            md: 'repeat(2,1fr)',
            lg: 'repeat(3,1fr)',
          }}
          gap="10"
        >
          {featuredPost}
        </Grid>
        <Box display={{ md: 'none' }} w="full">
          <Swiper
            spaceBetween={100}
            modules={[Autoplay, Pagination]}
            pagination={{ clickable: false }}
            autoplay={{
              disableOnInteraction: false,
              delay: 3000,
            }}
            loop={true}
          >
            {featuredPost}
          </Swiper>
        </Box>
        <Center pt="20px" pb={{ base: '0px', lg: '80px' }}>
          <Link href="/posts" passHref>
            <Button id="blogs-learn-btn" as="a" size="lg">
              View More
            </Button>
          </Link>
        </Center>
      </Flex>
    </Section>
  )
}

export default FeaturedArticles
