import {
  ActiveShowcaseItem,
  MotionContainer,
  MotionFlex,
  MotionText,
  Showcase,
  ShowcaseItem,
  useShowcaseStore,
} from '@/components'
import { AnimatePresence, Transition, Variants } from 'framer-motion'
import {
  Box,
  CloseButton,
  LinkBox,
  LinkOverlay,
  Text,
  VStack,
} from '@chakra-ui/react'

import ChacraIcon from '@/assets/images/Logo-01.svg'
import FeaturedProjectsTags from './FeaturedProjectsTags'
import Image from 'next/image'
import { Link } from '@chakra-ui/react'
import { Project } from '@/typings/project'
import { Section } from '@/layouts'
import { useState } from 'react'

export interface FeatureProjectsProps {
  projects: Project[]
}

const FeatureProjects = (props: FeatureProjectsProps) => {
  const activeShowcase = useShowcaseStore((state) => state.activeShowcase)
  const clearActiveShowcase = useShowcaseStore(
    (state) => state.clearActiveShowcase
  )

  const [isInView, setIsInView] = useState(false)

  const container: Variants = {
    initial: { opacity: 1 },
    animate: { opacity: 1 },
  }

  const text: Variants = {
    initial: {
      opacity: 0,
      scale: 0,
      transition: {
        duration: 0.5,
      },
    },
    animate: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
      },
    },
  }

  const defaultText: Variants = {
    initial: {
      opacity: 0,
      scale: 0,
      transition: {
        duration: 0.5,
      },
    },
    animate: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
        delay: 1.5,
      },
    },
  }

  const initialBox: Variants = {
    animate: {
      scale: 1,
      transition: {
        duration: 0.5,
      },
    },
    exit: {
      scale: 0,
      transition: {
        delay: 1,
        duration: 0.5,
      },
    },
  }

  const transition: Transition = {
    duration: 1,
  }

  const projectItems = props.projects.map((project) => (
    <ShowcaseItem key={project.id} {...project} />
  ))

  const projectItemsMobile = props.projects.map((project) => (
    <LinkBox key={project.id} w="100%">
      <Box
        position="relative"
        w="100%"
        height={{ base: '160px', xs: '280px', sm: '420px', md: '496px' }}
        rounded="3xl"
        overflow="hidden"
        border="1px"
        borderColor={project.color}
        mb={6}
      >
        <Image
          src={project.thumbnail}
          alt={`${project.name} thumbnail`}
          layout="fill"
          objectFit="cover"
          objectPosition="center center"
        />
      </Box>
      <LinkOverlay href={project.url} isExternal>
        <Text fontSize={{ base: 'xl' }} px={2}>
          <Text as="strong" color={project.color}>
            {project.name}
          </Text>{' '}
          <Text as="span" fontWeight={{ base: 'normal', md: 'semibold' }}>
            {project.description}
          </Text>
        </Text>
      </LinkOverlay>
    </LinkBox>
  ))
  return (
    <>
      <Section
        id="featured-projects"
        maxW="container.2xl"
        overflow="visible"
        display={{ base: 'none', lg: 'block' }}
      >
        <Box
          bgColor={activeShowcase?.bgColor ?? '#F3FFF8'}
          rounded="3xl"
          px="4"
          py="12"
          position="relative"
          transitionProperty="background-color"
          transitionDuration=".3s"
        >
          {activeShowcase && (
            <CloseButton
              zIndex={1}
              position="absolute"
              size="lg"
              top={6}
              right={6}
              onClick={() => clearActiveShowcase()}
              color={activeShowcase.descriptionColor}
            />
          )}
          <MotionFlex
            w="full"
            direction="column"
            variants={container}
            initial="initial"
            whileInView="animate"
            viewport={{
              amount: 0.5,
              once: true,
            }}
            onViewportEnter={() => setIsInView(true)}
          >
            <AnimatePresence exitBeforeEnter>
              {isInView && (
                <>
                  {activeShowcase ? (
                    <MotionText
                      key={activeShowcase.name}
                      color={activeShowcase.color}
                      fontSize="2xl"
                      minH="45px"
                      variants={text}
                      initial={isInView ? 'initial' : undefined}
                      animate={isInView ? 'animate' : undefined}
                      exit={isInView ? 'initial' : undefined}
                      // @ts-ignore chakra overrides transition type
                      transition={transition}
                    >
                      <Text as="strong">
                        <Link href={activeShowcase.url} isExternal>
                          {activeShowcase.name}
                        </Link>
                      </Text>{' '}
                      <Text as="strong" color={activeShowcase.descriptionColor}>
                        {activeShowcase.description}
                      </Text>
                    </MotionText>
                  ) : (
                    <MotionText
                      key="default-text"
                      color="darkgreen"
                      fontSize="3xl"
                      variants={defaultText}
                      initial="initial"
                      animate="animate"
                      exit="initial"
                      fontWeight="bold"
                      // @ts-ignore chakra overrides transition type
                      transition={transition}
                    >
                      Click and hold a project
                    </MotionText>
                  )}
                </>
              )}
            </AnimatePresence>
            <MotionFlex
              flex={1}
              justify="center"
              align="center"
              // overflow="hidden"
              viewport={{ amount: 0.5 }}
              onViewportLeave={() => {
                activeShowcase && clearActiveShowcase()
              }}
            >
              <AnimatePresence exitBeforeEnter>
                {!isInView ? (
                  <MotionFlex
                    key="initial-box"
                    bgColor="black"
                    w="330px"
                    h="180px"
                    mt="92px"
                    rounded="xl"
                    justify="center"
                    align="center"
                    variants={initialBox}
                    exit="exit"
                  >
                    <Image
                      src={ChacraIcon}
                      width="80px"
                      height="100%"
                      alt="chacra-icon"
                    />
                  </MotionFlex>
                ) : activeShowcase ? (
                  <Showcase key="active-showcase">
                    {activeShowcase.galleries.map((gallery) => (
                      <ActiveShowcaseItem
                        key={`gallery-${gallery.id}`}
                        borderColor={activeShowcase.color}
                        {...gallery}
                      />
                    ))}
                  </Showcase>
                ) : (
                  <Showcase key="showcase">{projectItems}</Showcase>
                )}
              </AnimatePresence>
            </MotionFlex>
            <MotionContainer
              maxW="container.xl"
              fontWeight="bold"
              color="gray.500"
              fontSize="lg"
              mt={32}
            >
              <FeaturedProjectsTags />
            </MotionContainer>
          </MotionFlex>
        </Box>
      </Section>
      <Section
        id="featured-projects-mobile"
        display={{ base: 'block', lg: 'none' }}
      >
        <VStack spacing={16}>{projectItemsMobile}</VStack>
      </Section>
    </>
  )
}

export default FeatureProjects
