import { ProjectTag } from '@/typings/project'
import { Text } from '@chakra-ui/react'
import includes from 'lodash/includes'
import { useShowcaseStore } from '@/components'

export interface FeaturedProjectsTagProps {
  tag: ProjectTag
}

const FeaturedProjectsTag = (props: FeaturedProjectsTagProps) => {
  const activeShowcase = useShowcaseStore((state) => state.activeShowcase)

  return (
    <Text
      color={
        includes(activeShowcase?.tags, props.tag)
          ? activeShowcase?.color
          : 'inherit'
      }
      transitionProperty="color"
      transitionDuration=".3s"
    >
      {props.tag}
    </Text>
  )
}

export default FeaturedProjectsTag
