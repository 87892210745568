import { Heading, Text, Box } from '@chakra-ui/react'

export interface OurValuesInfoItemProps {
  heading: string
  description: string
}

const OurValuesInfoItem = (props: OurValuesInfoItemProps) => {
  return (
    <Box w={{ base: 'full', md: '360px' }} color="white">
      <Heading fontSize={{ base: 'xl', lg: '3xl' }} mb="4">
        {props.heading}
      </Heading>
      <Text
        maxW="280px"
        mx="auto"
        fontFamily="Montserrat"
        fontSize={{ base: 'lg', lg: 'xl' }}
      >
        {props.description}
      </Text>
    </Box>
  )
}

export default OurValuesInfoItem
