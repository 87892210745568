import { MotionWrap, MotionWrapItem } from '@/components'

import FeaturedProjectsTag from './FeaturedProjectsTag'
import { Variants } from 'framer-motion'

const FeaturedProjectsTags = () => {
  const wrapItem: Variants = {
    initial: {
      opacity: 0,
      scale: 0,
    },
    animate: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 1,
      },
    },
  }

  return (
    <MotionWrap spacing={16} pb={35} justify="center">
      <MotionWrapItem variants={wrapItem}>
        <FeaturedProjectsTag tag="Frontend Development" />
      </MotionWrapItem>
      <MotionWrapItem variants={wrapItem}>
        <FeaturedProjectsTag tag="Web Design" />
      </MotionWrapItem>
      <MotionWrapItem variants={wrapItem}>
        <FeaturedProjectsTag tag="3D Design" />
      </MotionWrapItem>
      <MotionWrapItem variants={wrapItem}>
        <FeaturedProjectsTag tag="Cloud Computing" />
      </MotionWrapItem>
      <MotionWrapItem variants={wrapItem}>
        <FeaturedProjectsTag tag="Backend Development" />
      </MotionWrapItem>
      <MotionWrapItem variants={wrapItem}>
        <FeaturedProjectsTag tag="Hosting Services" />
      </MotionWrapItem>
      <MotionWrapItem variants={wrapItem}>
        <FeaturedProjectsTag tag="Quality Assurance" />
      </MotionWrapItem>
      <MotionWrapItem variants={wrapItem}>
        <FeaturedProjectsTag tag="Custom Animations" />
      </MotionWrapItem>
      <MotionWrapItem variants={wrapItem}>
        <FeaturedProjectsTag tag="Payment Processing" />
      </MotionWrapItem>
      <MotionWrapItem variants={wrapItem}>
        <FeaturedProjectsTag tag="Booking System" />
      </MotionWrapItem>
    </MotionWrap>
  )
}

export default FeaturedProjectsTags
